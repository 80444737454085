// Materia 5.1.3
// Bootswatch

$theme: "materia" !default;


//
// Color system
//custom colors
$sapphire-company-blue: #209de2 !default;
$sapphire-complimentary-company-blue: #187EB7 !default;
$priority-immediate: #fc440f;
$priority-high: #fec601;
$priority-medium: #8cd867;
$priority-low:#3da5d9;





// Default colors
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: #666 !default;
$gray-700: #444 !default;
$gray-800: #222 !default;
$gray-900: #212121 !default;
$black:    #000 !default;

$blue:    #2196f3 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #e51c23 !default;
$orange:  #fd7e14 !default;
$yellow:  #ff9800 !default;
$green: #4caf50 !default;
$teal:    #20c997 !default;
$cyan:    #9c27b0 !default;

$primary:       $sapphire-company-blue !default;
$secondary:     $sapphire-complimentary-company-blue !default;
$success:       $green !default;
$info:          $sapphire-company-blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$min-contrast-ratio:   2.15 !default;

$enable-gradients: true !default;

//Emerald Custom Colors
$emerald-company-green : #00b187 !default;
$emerald-complimentary-company-green : #008f6b !default;

//Custom Alert Colors

$emerald-primary:       $emerald-company-green !default;
$emerald-secondary:     $emerald-complimentary-company-green !default;
$emerald-success:       $green !default;
$emerald-info:          $blue !default;
$emerald-warning:       $yellow !default;
$emerald-danger:        $red !default;
$emerald-light:         $gray-100 !default;
$emerald-dark:          #7e8083 !default;

$emerald-primary-faded: rgba($emerald-primary,.5);
$emerald-secondary-faded: rgba($emerald-secondary,.5);
$emerald-success-faded: rgba($emerald-success,.5);
$emerald-info-faded: rgba($emerald-info,.5);
$emerald-warning-faded: rgba($emerald-warning,.5);
$emerald-danger-faded: rgba($emerald-danger,.5);

////END EMERALD


.emerald-divider {
    height: 3rem;
    //background-color: rgba(0, 0, 0, .1);
    background-image: linear-gradient(to bottom, $emerald-company-green, $emerald-secondary);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .3), inset 0 .125em .5em rgba(0, 0, 0, .35);
}


//Custom Alert Colors

$primary-faded: rgba($primary,.25);
$secondary-faded: rgba($secondary,.25);
$success-faded: rgba($success,.25);
$info-faded: rgba($info,.25);
$warning-faded: rgba($warning,.25);
$danger-faded: rgba($danger,.25);

//Custom Colors
$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    #fb8c00,
    "danger":     #f44335,
    "light":      $light,
    "dark":       $dark,
    'emerald-primary':$emerald-primary,
    'emerald-secondary':$emerald-secondary,
    'emerald-success':$emerald-success,
    'emerald-info':   $emerald-info,
    'emerald-warning':$emerald-warning,
    'emerald-danger': $emerald-danger,
    'emerald-light':  $emerald-light,
    'emerald-dark':  $emerald-dark,

);
// Create your own map
$custom-colors: (
    "priority-immediate": $priority-immediate,
    "priority-high": $priority-high,
    "priority-medium": $priority-medium,
    "priority-low": $priority-low,
    "primary-faded": $primary-faded,
    "secondary-faded": $secondary-faded,
    "info-faded": $info-faded,
    "warning-faded": $warning-faded,
    "danger-faded": $danger-faded,
    "success-faded": $success-faded,
);
//add my custom colors to the theme map
// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);
// Body

$body-color:    $gray-700 !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif:    Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;
$font-size-base:            1rem !default;
$font-weight-base:          400 !default;

// Buttons

//$input-btn-padding-y:       .8rem !default;
//$input-btn-padding-x:       1rem !default;

// Forms

$input-padding-y:                1rem !default;
$input-padding-x:                1rem !default;
$input-padding-y-sm:             .5rem !default;
$input-padding-x-sm:             .5rem !default;
$input-font-size-sm:            inherit !default;
$input-padding-y-lg:             ($font-size-base * 1.25) !default;
$input-padding-x-lg:             0 !default;
$input-bg:                      $gray-200 !default;
$input-disabled-bg:              transparent !default;
$input-color:                    $black !default;
//$input-border-color:             transparent !default;
//$input-border-width:             0 !default;
//$input-border-radius:            0 !default;
//$input-border-radius-lg:         0 !default;
//$input-border-radius-sm:         0 !default;
$input-placeholder-color:        rgba(0, 0, 0, .4) !default;
//$input-group-addon-bg:           transparent !default;

// Navs

$nav-link-disabled-color:       $gray-500 !default;
$nav-tabs-border-color:         transparent !default;

// Navbar

$navbar-padding-y:                  1rem !default;
$navbar-dark-color:                 rgba($white, .75) !default;
$navbar-dark-hover-color:           $white !default;


// Cards

$card-border-width:                 0 !default;
$card-border-color:                 transparent !default;

// Tooltips

$tooltip-bg:                        $gray-700 !default;

// Modals

$modal-content-border-color:        transparent !default;

// Progress bars

$progress-height:               .375rem !default;
$progress-border-radius:        0 !default;

// Close

$btn-close-color:            $white !default;
$btn-close-opacity:          .6 !default;
$btn-close-hover-opacity:    1 !default;

//$dropdown-link-hover-color:               $sapphire-company-blue;
$dropdown-link-hover-bg: rgba(32, 157, 226, 0.25);

.navbar .nav-item{
    -webkit-transition: background-color 1s ease-out;
    -moz-transition: background-color 1s ease-out;
    -o-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out;
    border-radius: 25px;

}
.navbar .nav-item:hover{
    background-color:rgba(32, 157, 226, 0.25) ;
    border-radius: 25px;

}
.dropdown .nav-item{

    border-radius: 0 !important;
    border-bottom: 1px solid $gray-500;

}

.dropdown .nav-item:hover{
    background-color:rgba(32, 157, 226, 0.25) ;
    border-radius: 0 !important;

}


.sapphire-divider {
    height: 3rem;
    //background-color: rgba(0, 0, 0, .1);
    background-image: linear-gradient(to bottom, $sapphire-company-blue, #218ee3);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .3), inset 0 .125em .5em rgba(0, 0, 0, .35);
}


//Custom "Sub Section" form color

.form-subsection {
    background-color: rgba(32,157,226,.25);
    border: 1px solid $gray-200;
   // border-radius: 25px;
    padding: 15px;
    margin-bottom: 1rem;
}

.icon-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    border-radius: .75rem;
}
.feature-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #fff;
    border-radius: .75rem;
}

//Stamps for invoice status, or anything really
.stamp {
    transform: rotate(12deg);
    color: #555;
    //font-size: 3rem;
    font-weight: 700;
    border: 0.25rem solid #555;
    display: inline-block;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    border-radius: 1rem;
    //font-family: 'Courier';
    -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
    -webkit-mask-size: 944px 604px;
    mix-blend-mode: multiply;
}

.is-nope {
    color: $danger;
    border: 0.5rem double $danger;
    transform: rotate(3deg);
    -webkit-mask-position: 2rem 3rem;
   // font-size: 2rem;
}

.is-approved {
    color: $success;
    border: 0.5rem solid $success;
    -webkit-mask-position: 13rem 6rem;
    transform: rotate(-14deg);
    border-radius: 0;
}

.is-draft {
    color: $light;
    border: 1rem double $light;
    transform: rotate(-5deg);
    font-size: 6rem;
    border-radius: 0;
    padding: 0.5rem;
}

//Clocklet

.mc-calendar--modal{
    z-index: 10000 !important;
}

.mc-calendar--inline{
    z-index: 10000 !important;
}

//Emerald CSS
.emerald-navbar .nav-item:hover{
    background-color:rgba($emerald-primary, 0.25) ;
    border-radius: 25px;

}
.emerald-dropdown .nav-item:hover{
    background-color:rgba($emerald-secondary, 0.25) ;
    border-radius: 0 !important;

}
