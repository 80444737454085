// Materia 5.2.0
// Bootswatch


// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700&display=swap" !default;
@if $web-font-path {
    @import url($web-font-path);
}

// Mixins

@mixin ripple($color) {
    position: relative; //this makes the "stretched link" option not work

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        margin-left: 0;
        pointer-events: none;
        content: "";
        background-position: 50%;
        background-size: 1000% 1000%;
        border: none;
        opacity: 0;
        transition: background .5s, opacity 1s;
        @include gradient-radial($color 10%, transparent 10.01%);
    }

    &:active::before {
        background-size: 0 0;
        opacity: .2;
        transition: none;
    }

    &.disabled,
    &[disabled] {
        &::before {
            display: none;
        }
    }
}

@mixin btn($class,$bg,$color) {
    .btn-#{$class} {
        &:focus {
            background-color: $bg;
            box-shadow: 0 0 0 2px rgba(204, 204, 204, .5);
        }

        &:hover,
        &:active:hover {
            background-color: shade-color($bg, 12%);
        }

        &:active {
            box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
        }

        @include ripple($color);
    }

    .btn-outline-#{$class} {
        @include ripple($color);
    }
}

.dropdown-toggle::after,
.dropup .dropdown-toggle::after,
.dropstart .dropdown-toggle::after,
.dropend .dropdown-toggle::after {
    border-width: 4px;
}

// Navbar

.navbar {
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3);

    &-brand {
        font-size: 24px;
    }

    &-nav .nav-link {
        padding-top: .9rem;
        padding-bottom: .9rem;
    }

    &.bg-dark,
    &.bg-primary {
        input[type="search"],
        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="number"],
        input[type="tel"] {
            color: $white;
            box-shadow: inset 0 -1px 0 rgba(255, 255, 255, .5);

            &:focus {
                box-shadow: inset 0 -2px 0 $white;
            }

            &::placeholder {
                color: rgba(255, 255, 255, .5);
            }
        }
    }
}

// Buttons

@include btn(primary, $primary, $white);
@include btn(secondary, $secondary, $gray-500);
@include btn(success, $success, $white);
@include btn(info, $info, $white);
@include btn(warning, $warning, $white);
@include btn(danger, $danger, $white);
@include btn(dark, $dark, $white);
@include btn(light, $light, $white);

.btn {
    text-transform: uppercase;
    border: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .4);
    transition: color .4s, background-color .4s, border-color .4s, box-shadow .4s;

    &-link {
        color: $link-color;
        box-shadow: none;

        &:hover,
        &:focus {
            color: $link-hover-color;
            text-decoration: $link-hover-decoration;
            box-shadow: none;
        }

        &.disabled, // Although btn-link is intended for buttons, which want to look like link, I include here a.disable for the sake of consistency
        &[disabled],
        fieldset[disabled] & {
            &:hover,
            &:active:hover {
                color: $btn-link-disabled-color;
                text-decoration: none;
            }
        }
    }

    &-secondary {
        &.disabled,
        &[disabled],
        fieldset[disabled] & {
            color: rgba(0, 0, 0, .4);
            background-color: rgba(0, 0, 0, .1);
            opacity: 1;

            &:hover,
            &:focus {
                background-color: rgba(0, 0, 0, .1);
            }
        }
    }

    &-outline-secondary {
        color: $gray-300;
        border-color: $gray-200;
    }

    &-warning {
        color: $white;
    }
}

.btn-group {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        margin-left: 0;
    }

    &-vertical {
        > .btn + .btn,
        > .btn + .btn-group,
        > .btn-group + .btn,
        > .btn-group + .btn-group {
            margin-top: 0;
        }
    }

    .btn + .btn,
    .btn + .btn-group > .dropdown-toggle {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, .4);
    }
}

.border-secondary {
    border: 1px solid #dfdfdf !important;
}

// Typography

body,
input,
button {
    letter-spacing: .1px;
}

p {
    margin: 0 0 1em;
}

.text-secondary {
    color: $gray-500 !important;
}

// Tables



.table-hover {
    > tbody > tr,
    > tbody > tr > th,
    > tbody > tr > td {
        transition: background-color .2s, color .2s;
    }
}

.thead-inverse th {
    color: $white;
    background-color: $primary;
}

// Forms

.col-form-label {
    font-size: 16px;

    &-sm {
        font-size: $font-size-sm;
    }

    &-lg {
        font-size: $font-size-lg;
    }
}

textarea,
textarea.form-control,
input.form-control,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
[type="text"].form-control,
[type="password"].form-control,
[type="email"].form-control,
[type="tel"].form-control,
[contenteditable].form-control {
    box-shadow: inset 0 -1px 0 #ddd;
    transition: box-shadow .2s;

    &:focus {
        box-shadow: inset 0 -2px 0 $primary;
    }

    &[disabled],
    &[readonly] {
        border-bottom: 1px dotted #ddd;
        box-shadow: none;
    }

    &[disabled],
    &[disabled]::placeholder {
        color: #ddd;
    }
}

select,
select.form-control {
    padding: .5rem 0;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 4'><path fill='#{$gray-600}' d='M8 0 4 4 0 0z'/></svg>"));
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 8px 4px;
    box-shadow: inset 0 -1px 0 #ddd;
    appearance: none;

    &.input {
        &-sm {
            font-size: $font-size-sm;
        }

        &-lg {
            font-size: $font-size-lg;
        }
    }

    &:focus {
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 4'><path fill='#{$gray-900}' d='M8 0 4 4 0 0z'/></svg>"));
        box-shadow: inset 0 -2px 0 $primary;
    }

    &[multiple] {
        background: none;
    }
}

.form-check-input {
    width: 1.25em;
    height: 1.25em;
    margin-top: 1px;
    border: 2px solid $gray-400;

    &:checked[type="radio"] {
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$blue}' stroke='#{$white}'/></svg>")), var(--bs-gradient);
        background-size: 1.8em;
    }
}

.form-switch {
    .form-check-input {
        position: relative;
        height: .8em;
        margin-top: .29em;
        background-color: $gray-400;
        background-image: none;
        border: none;

        &:focus {
            box-shadow: none;
        }

        &::before {
            position: absolute;
            top: -.2em;
            left: -.2em;
            width: 1.2em;
            height: 1.2em;
            content: "";
            background-color: $white;
            border-radius: 50%;
            box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
            transition: left .15s ease-in-out;
        }

        &::after {
            position: absolute;
            top: -.2em;
            left: -.2em;
            z-index: -1;
            width: 1.2em;
            height: 1.2em;
            content: "";
            border-radius: 50%;
            box-shadow: 0 0 0 9px rgba(0, 0, 0, .05);
            transition: left .15s ease-in-out, transform .15s ease-in-out, box-shadow .15s ease-in-out;
            transform: scale(0);
        }

        &:hover:not(.disabled),
        &:focus:not(.disabled) {

            &::after {
                transform: scale(.9);
            }
        }

        &:focus:not(.disabled) {
            &::after {
                box-shadow: 0 0 0 9px rgba(0, 0, 0, .1);
            }
        }

        &:checked {
            background-color: rgba(33, 150, 243, .3);

            &::before {
                left: calc(100% - .8em);
                background-color: rgba(33, 150, 243, 1);
            }

            &::after {
                left: calc(100% - .8em);
                box-shadow: 0 0 0 9px rgba(33, 150, 243, .1);
            }

            &:hover:not(.disabled),
            &:focus:not(.disabled) {
                &::after {
                    transform: scale(.9);
                }
            }

            &:focus:not(.disabled) {
                &::after {
                    box-shadow: 0 0 0 9px rgba(33, 150, 243, .2);
                }
            }
        }
    }
}

.form-check:not(.form-switch) {
    .form-check-input {
        &:checked[type="checkbox"] {
            background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$white}' stroke-width='2' d='m6 10 3 3 6-6'/></svg>")), var(--bs-gradient);
            background-size: 1.6em;
        }
    }
}

.has-warning {
    input:not([type="checkbox"]),
    .form-control,
    input.form-control[readonly],
    input[type="text"][readonly],
    [type="text"].form-control[readonly],
    input:not([type="checkbox"]):focus,
    .form-control:focus {
        border-bottom: none;
        box-shadow: inset 0 -2px 0 $warning;
    }
}

.has-danger {
    input:not([type="checkbox"]),
    .form-control,
    input.form-control[readonly],
    input[type="text"][readonly],
    [type="text"].form-control[readonly],
    input:not([type="checkbox"]):focus,
    .form-control:focus {
        border-bottom: none;
        box-shadow: inset 0 -2px 0 $danger;
    }
}

.has-success {
    input:not([type="checkbox"]),
    .form-control,
    input.form-control[readonly],
    input[type="text"][readonly],
    [type="text"].form-control[readonly],
    input:not([type="checkbox"]):focus,
    .form-control:focus {
        border-bottom: none;
        box-shadow: inset 0 -2px 0 $success;
    }
}

// Remove the Bootstrap feedback styles for input addons
.input-group-addon {
    .has-warning &,
    .has-danger &,
    .has-success & {
        color: $input-color;
        background-color: $input-group-addon-bg;
        border-color: $input-group-addon-border-color;
    }
}

.form-group-lg {
    select,
    select.form-control {
        line-height: 1.5;
    }
}

// Navs

.nav-tabs {
    .nav-item + .nav-item {
        margin-left: 0;
    }

    .nav-link,
    .nav-link:focus {
        margin-right: 0;
        color: $body-color;
        background-color: transparent;
        border: none;
        box-shadow: inset 0 -1px 0 #ddd;
        transition: color .2s, box-shadow .2s;

        &:hover {
            color: $primary;
            background-color: transparent;
            box-shadow: inset 0 -2px 0 $primary;
        }
    }

    .nav-link.active,
    .nav-link.active:focus {
        color: $primary;
        border: none;
        box-shadow: inset 0 -2px 0 $primary;

        &:hover {
            color: $primary;
            border: none;
        }
    }

    .nav-link.disabled {
        box-shadow: inset 0 -1px 0 #ddd;
    }

    &.nav-justified {
        .nav-link,
        .nav-link:hover,
        .nav-link:focus,
        .nav-link.active,
        .nav-link.active:hover,
        .nav-link.active:focus {
            border: none;
        }
    }

    .dropdown-menu {
        margin-top: 0;
    }
}

.dropdown-menu {
    margin-top: 0;
    border: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
}

// Indicators

.alert {
    padding-right: 2.5rem;
    //border: none;



    @each $color, $value in $theme-colors {
        &-#{$color} {
                //changed from default, i like faded alerts
                background-color: rgba($value, 0.1);
               // border-color: $value;

        }
    }

    a:not(.btn),
    .alert-link {
        font-weight: 700;

    }

    &-secondary,
    &-light {
        &,
        a:not(.btn),
        .alert-link {

        }
    }
}

.badge {
    &.bg-secondary,
    &.bg-light {
        color: $dark;
    }
}

.tag {
    padding: 4px 6px;
}

// Containers

.card {
    box-shadow: 0 1px 10px rgba(0, 0, 0, .15);

    &.border-primary,
    &.border-secondary,
    &.border-success,
    &.border-info,
    &.border-warning,
    &.border-danger,
    &.border-light,
    &.border-dark {
        border-width: 1px;
    }
}

.list-group {
    &-item-action.active {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }
    }
}

.modal-content {
    border-radius: .2rem;
    box-shadow: 0 6px 36px rgba(0, 0, 0, .3);
}

.modal,
.toast,
.offcanvas {
    .btn-close {
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$black}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>"));
    }
}

.popover {
    border: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
}

.carousel {
    &-caption {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: inherit;
        }
    }
}
